import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlansModule } from './../../shared/components/plans/plans.module';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    FlexLayoutModule,
    PlansModule,
    CommonModule,
    Ae2CarouselContainerModule,
    RouterModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
