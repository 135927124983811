<div>
    <app-header></app-header>

    <div>
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>



