import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})

export class FooterComponent {
    positionLat: number = -20.818617;
    positionLng: number = -49.378897;
    lat: number = -20.818658;
    lng: number = -49.382504;
    zoom: number = 17;
    scrollwheel: boolean = false;
    icon: string = '/assets/images/mixd/mapa-marcador.png';

    customStyle: any =
    [
      {
          stylers: [
              {
                hue: '#c5c7c9'
              },
              {
                visibility: 'simplified'
              },
              {
                gamma: 1.5
              },
              {
                saturation: -100
              },
              {
                weight: 1
              }
          ]
      },
      {
          elementType: 'labels',
          stylers: [
              {
                visibility: 'on'
              }
          ]
      },
      {
          featureType: 'water',
          stylers: [
              {
                color: '#9ca1a6'
              }
          ]
      }
    ];
}
