import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlansComponent } from './plans.component';
import { Ae2PackageListContainerModule } from '@angularecommerce/core/components/package-list-container';
import { Ae2AddToCheckoutModule } from '@angularecommerce/core/directives/add-to-checkout';
import { Ae2PackageWidgetModule } from '@angularecommerce/core/components/package-widget';
import { MatButtonModule } from '@angular/material';
import { LocalizationModule } from 'angular-l10n';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    Ae2AddToCheckoutModule,
    Ae2PackageListContainerModule,
    Ae2PackageWidgetModule,
    MatButtonModule,
    LocalizationModule
  ],
  declarations: [PlansComponent],
  exports: [PlansComponent]
})
export class PlansModule { }
