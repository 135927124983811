<ae2-package-list-container>
  <ng-template #packageListTemplate let-packages="results">
    <div class="planos-title">
      <div class="container">
        <div fxLayout="row">
          <div fxFlex="100">
            <h2 class="title medium laranja after center">Escolha seu plano</h2>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="100">
            <p>Nosso objetivo é oferecer saúde, bem-estar e superação dos próprios limites para aqueles que
              nunca
              frequentaram uma
              <br>academia ou um ambiente fitnes ou até mesmo aqueles que já foram em uma academia.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="planos-content">
      <div class="planos-content_container" fxLayout="row wrap" fxLayoutAlign="center center">
        <ng-container *ngFor="let package of packages">
          <div [ngStyle]="{'min-height': '475px'}" fxLayoutAlign="center center">
            <div>
              <ae2-package-widget [data]="package">
                <ng-template let-package>
                  <div class="ae2-pkg-card" [ngClass]="package.tags" [style.background-color]="package?.colorCode">

                    <div class="ae2-pkg-card_header" fxLayout fxLayoutAlign="center center">
                      <h1 class="ae2-pkg-header_title">{{ package.name }}</h1>
                    </div>

                    <div class="ae2-pkg-card_content" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-between center">

                      <ng-container *ngIf="!package?.eventCreditIsUnlimited">
                        <p class="ae2-pkg-content_price">
                          R${{ package.currentPrice / package.eventCreditQuantity | number:'1.0-0' }}
                        </p>
                      </ng-container>

                      <!-- <ng-container *ngIf="package?.eventCreditIsUnlimited">
												<p class="ae2-pkg-content_price">R${{ (package.currentPrice * 30) / (package.currentEventCreditValidity *
													package.currentEventCreditQuantity)
													| number:'1.0-0'}}</p>
											</ng-container> -->

                      <span class="simple-text ae2-pkg-content_span">
                        <ng-container *ngIf="!package?.eventCreditIsUnlimited">
                          <ng-container *ngIf="package?.eventCreditQuantity > 1">
                            valor / aula
                          </ng-container>
                        </ng-container>
                      </span>

                      <span class="simple-text ae2-pkg-content_span">
                        <ng-container *ngIf="!package?.eventCreditIsUnlimited">
                          <ng-container *ngIf="package?.eventCreditIsUnlimited">
                            caso fizer {{package?.eventCreditQuantity}} aulas no mês
                          </ng-container>
                        </ng-container>
                      </span>

                      <span class="simple-text ae2-pkg-content_span">
                        <ng-container *ngIf="package?.tagline">
                          {{package.tagline}}
                        </ng-container>
                      </span>

                      <!-- <span class="simple-text ae2-pkg-content_span">
												<ng-container *ngIf="package?.installmentsInfo?.creditcard?.installments?.length <= 1">
													<ae2-pw-card-amount></ae2-pw-card-amount>
												</ng-container>
											</span> -->

                      <span class="simple-text ae2-pkg-content_span">
                        <div fxLayout>
                          <!-- <ng-container *ngIf="package?.installmentsInfo?.creditcard?.installments?.length > 1"> -->
                          <ae2-pw-card-amount></ae2-pw-card-amount>
                          <!-- </ng-container> -->
                        </div>
                      </span>

                      <div class="ae2PkgCard_content_tagLine simple-text">
                        <ae2-pw-card-credits></ae2-pw-card-credits><br>
                        <ng-container *ngIf="package.recurrencyEnabled">
                          <span class="ae2-pkg-content_validity">
                            <ng-container *ngIf="package.recurrencyMinimumStay > 1;else minimumStayEqualOne">
                              Permanência mínima de {{package.recurrencyMinimumStay}} meses
                            </ng-container>

                            <ng-template #minimumStayEqualOne>
                                Permanência mínima de {{ package.recurrencyMinimumStay }} mês
                            </ng-template>
                          </span>
                        </ng-container>
                        <ng-container *ngIf="!package.recurrencyEnabled">
                          <ae2-pw-card-validity></ae2-pw-card-validity>
                        </ng-container>
                      </div>

                      <div class="ae2-pkg-card_actions" fxLayout fxLayoutAlign="center center">
                        <button class="ae2-pkg-card_action" mat-raised-button color="primary"
                          [ae2AddToCheckout]="package.id" [rules]="package.rules">comprar</button>
                      </div>

                      <ae2-pw-card-share-info></ae2-pw-card-share-info>

                    </div>
                  </div>
                </ng-template>

              </ae2-package-widget>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>

</ae2-package-list-container>
