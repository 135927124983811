<footer id="scroll-footer">

<ae2-site-settings>
    <ng-template ae2SiteSettingsContent let-site="site">
        <div class="top">
            <div class="container">
                <div fxLayout="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" class="row">
                    <div fxFlex="50" fxFlex.md="40" fxFlex.sm="auto" fxFlex.xs="auto" class="column">
                        <div fxLayout="row" class="row">
                            <div fxFlex="100" class="column">
                                <h2 class="title small after laranja">Fale com a gente</h2>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayout.md="column" fxLayout.sm="row" fxLayout.xs="column" class="row">
                            <div fxFlex="50" fxFlex.md="100" fxFlex.sm="auto" fxFlex.xs="auto" class="column">
                                <a href="https://www.google.com.br/maps/place/{{ site.address1 + ', ' + site.address2 + ' - ' + site.address4 + ' - ' + site.city + ' - ' + site.state}}" class="simple-text" target="_blank">
                                    {{ site.address1 + ', ' + site.address2 + (site.address3 != "" && site.address3 != undefined ? ', ' + site.address3 : '') + ' - ' + site.address4 }} <br>
                                    {{ site.city + ' - ' + site.state }}
                                </a>
                            </div>
                            <div fxFlex="50" fxFlex.md="100" fxFlex.sm="auto" fxFlex.xs="auto" class="column">
                                <div fxLayout="row" class="row">
                                    <div fxFlex="100" class="column">
                                        <a href="tel:{{ site.phone.replace('+', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '').replace(' ', '').replace(' ', '').replace(' ', '') }}" class="simple-text icone-whatsapp">telefone <span><strong>{{ site.phone }}</strong></span></a>
                                    </div>
                                </div>
                                <div fxLayout="row" class="row">
                                    <div fxFlex="100" class="column">
                                        <a href="mailto:{{ site.contactEmail }}" class="simple-text">e-mail <span>{{ site.contactEmail }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxFlex="50" fxFlex="60" fxFlex.sm="auto" fxFlex.xs="auto" class="column">
                        <div class="formulario-container">
                            <div class="formulario">
                                <ae2-contact-form
                                    code="contato"
                                    subject="Beatcycle - Formulário Contato"
                                    [fields]="{
                                        name: { required: true },
                                        email: { required: true, validEmail: true },
                                        phone: { required: false },
                                        message: { required: true }
                                    }">
                                    <ng-template #contactFormTemplate let-form="form" let-methods="methods">
                                        <form [formGroup]="form" (submit)="methods.submit($event)">
                                            <div fxLayout="row" fxLayout.md="column" fxLayout.sm="row" fxLayout.xs="column" class="row">
                                                <div fxFlex="60" fxFlex.md="100" fxFlex.sm="auto" fxFlex.xs="auto" class="column">
                                                    <input formControlName="name" placeholder="nome" class="input-text">
                                                    <ae2-control-messages [control]="form.get('name')"></ae2-control-messages>
                                                </div>
                                                <div fxFlex="40" fxFlex.md="100" fxFlex.sm="auto" fxFlex.xs="auto" class="column">
                                                    <input formControlName="phone" placeholder="telefone" class="input-text">
                                                    <ae2-control-messages [control]="form.get('phone')"></ae2-control-messages>
                                                </div>
                                            </div>
                                            <!--<div fxLayout="row" fxLayout.md="column" fxLayout.sm="row" fxLayout.xs="column" class="row">
                                                <div fxFlex="40" fxFlex.md="100" fxFlex.sm="40" fxFlex.xs="auto" class="column">
                                                    <input type="text" name="telefone" id="telefone" class="input-text" placeholder="telefone">
                                                </div>
                                                <div fxFlex="45" fxFlex.md="100" fxFlex.sm="45" fxFlex.xs="auto" class="column">
                                                    <input type="text" name="cidade" id="cidade" class="input-text" placeholder="cidade">
                                                </div>
                                                <div fxFlex="15" fxFlex.md="100" fxFlex.sm="15" fxFlex.xs="auto" class="column">
                                                    <mat-select placeholder="uf">
                                                        <mat-option [value]="sp">sp</mat-option>
                                                        <mat-option [value]="rj">rj</mat-option>
                                                        <mat-option [value]="sc">sc</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>-->
                                            <div fxLayout="row" fxLayout.md="column" fxLayout.sm="row" fxLayout.xs="column" class="row">
                                                <div fxFlex="100" class="column" fxFlex.xs="auto">
                                                    <input formControlName="email" placeholder="e-mail" class="input-text">
                                                    <ae2-control-messages [control]="form.get('email')"></ae2-control-messages>
                                                </div>
                                            </div>
                                            <div fxLayout="row" class="row">
                                                <div fxFlex="100" class="column" fxFlex.xs="auto">
                                                    <textarea formControlName="message" placeholder="deixa sua mensagem" class="input-area"></textarea>
                                                    <ae2-control-messages [control]="form.get('message')"></ae2-control-messages>
                                                </div>
                                            </div>
                                            <div fxLayout="row" class="row">
                                                <div fxLayout.md="column" fxFlex="100" class="column" fxFlex.xs="auto">
                                                    <input type="submit" [disabled]="form.invalid" value="enviar" class="button-send-form">
                                                </div>
                                            </div>
                                        </form>
                                    </ng-template>
                                </ae2-contact-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mid">
            <agm-map [latitude]="positionLat" [longitude]="positionLng" [zoom]="zoom" [scrollwheel]="scrollwheel" [styles]="customStyle">
                <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="icon"></agm-marker>
            </agm-map>
        </div>

        <div class="bot">
            <div class="container">
                <div fxLayout="row" class="row">
                    <div fxFlex="100" class="column">
                        <a routerLink="/" class="logo-footer">
                            <img src="/assets/images/mixd/logo-footer.png" alt="Logo Beatcycle.">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ae2-site-settings>

</footer>
