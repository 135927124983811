import { Component, OnInit, HostListener } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  sidenavMode: string;
  sidenavOpened: boolean = false;
  sidenavAlign: string;
  showHeader: boolean = true;
  showFooter: boolean = true;
  isHeaderFixed: boolean = false;
  isHeaderTransparent: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.observerMQ();
  }
  constructor(
    protected observableMedia: ObservableMedia
  ) { }

  ngOnInit(): void {
    this.observerMQ();
  }

  observerMQ(): void {
    if (this.observableMedia.isActive('gt-sm')) {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
      this.sidenavAlign = 'start';
    } else {
      this.sidenavOpened = false;
      this.sidenavMode = 'push';
      this.sidenavAlign = 'start';
    }
  }

}
