import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { MatInputModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { MatSelectModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2ContactFormModule } from '@angularecommerce/core/components/contact-form';
import { Ae2ControlMessagesModule } from '@angularecommerce/core/components/control-messages';
import { BrowserModule } from '@angular/platform-browser';
import { AgmCoreModule } from '@agm/core';
import { GOOGLE_MAPS_KEY } from 'app/core/constants/constants';

@NgModule({
  imports: [
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    MatSelectModule,
    RouterModule,
    CommonModule,
    BrowserModule,
    Ae2SiteSettingsModule,
    Ae2ContactFormModule,
    Ae2ControlMessagesModule,
    AgmCoreModule.forRoot({
      apiKey: GOOGLE_MAPS_KEY
    })
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
