import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule'
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule'
  },
  {
    path: 'pacotes',
    loadChildren: 'app/features/activities/activities.module#ActivitiesModule'
  },

  {
    path: 'pacotes/:activitySlug/:eventSlug/:eventToken',
    loadChildren: 'app/features/event/event.module#EventModule'
  },
  {
    path: 'evento/:activitySlug/:eventSlug/:eventToken',
    loadChildren: 'app/features/event/event.module#EventModule'
  },
  {
    path: 'atividades/:activitySlug/:eventSlug/:eventToken',
    redirectTo: 'pacotes/:activitySlug/:eventSlug/:eventToken'
  },
  // {
  //   path: 'pacotes/:slug/:id',
  //   loadChildren: 'app/features/activity-detail/activity-detail.module#ActivityDetailModule'
  // },
  {
    path: 'instrutores',
    loadChildren: 'app/features/instructors/instructors.module#InstructorsModule'
  },
  // {
  //   path: 'instrutor/:slug/:id',
  //   loadChildren: 'app/features/instructor-detail/instructor-detail.module#InstructorDetailModule'
  // },
  {
    path: 'estudio',
    loadChildren: 'app/features/studios/studios.module#StudiosModule'
  },
  // {
  //   path: 'studio/:slug/:id',
  //   loadChildren: 'app/features/studios-detail/studios-detail.module#StudiosDetailModule'
  // },
  // {
  //   path: 'login',
  //   loadChildren: 'app/features/login/login.module#LoginModule'
  // },
  // {
  //   path: 'categoria/:categoryId',
  //   loadChildren: 'app/features/products/products.module#ProductsModule'
  // },
  // {
  //   path: 'categoria/:categoryId/subcategoria/:subcategoryId',
  //   loadChildren: 'app/features/products/products.module#ProductsModule'
  // },
  // {
  //   path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
  //   loadChildren: 'app/features/products/products.module#ProductsModule'
  // },

  // {
  //   path: 'minha-conta',
  //   canActivate: [Ae2AuthRouteGuardService],
  //   loadChildren: 'app/features/profile/profile.module#ProfileModule'
  // },
  // {
  //   path: 'minha-conta',
  //   canActivate: [Ae2AuthRouteGuardService],
  //   loadChildren: 'app/features/new-account/new-account.module#NewAccountModule'
  // },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule'
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule'
  },
  {
    path: 'clientes/resetar-senha/:uidb36/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule'
  },
  {
    path: 'checkout',
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule'
  },

  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule'
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule'
  },

  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    HomeModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
