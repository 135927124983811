<ae2-header-layout-placement-widget></ae2-header-layout-placement-widget>

<ae2-header-widget [config]="headerConfig" [signupConfig]="signupConfig">
  <ng-template #headerTemplate let-header let-config="config" let-settings="settings" let-preHeaderHeight="preHeaderHeight">

    <ng-container *ngIf="header.isPreHeaderOpened">
      <ng-container *ngIf="!header.isMobile; else mobilePreHeader">

        <div class="ae2-header-widget-login-or-signup" fxLayout="row" fxLayoutAlign="center start">

          <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN" (onCloseLogin)="header.closeLoginOrSignup()"
            (onOpenSignup)="header.openSignup()" (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
          <ae2-sign-up-top class="pre-header-element ae2-sign-up-top" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.SIGNUP"
            (onCloseSignup)="header.closeLoginOrSignup()" (onOpenLogin)="header.openLogin()" (onOpenForgot)="header.openForgot()"
            [config]="header.signupConfig"></ae2-sign-up-top>
          <ae2-forgot-password-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT"
            (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
            (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
          <ae2-forgot-password-done-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
            (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgotDone)="header.closeLoginOrSignup()"
            (onOpenLoginDone)="header.openLogin()"></ae2-forgot-password-done-top>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #mobilePreHeader>
      <div class="ae2-header-widget-pre-header-mobile">
        <ae2-sign-in-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.LOGIN" (onCloseLogin)="header.closeLoginOrSignup()"
          (onOpenSignup)="header.openSignup()" (onOpenForgot)="header.openForgot()"></ae2-sign-in-top>
        <ae2-sign-up-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.SIGNUP" (onCloseSignup)="header.closeLoginOrSignup()"
          (onOpenLogin)="header.openLogin()" (onOpenForgot)="header.openForgot()" [config]="header.signupConfig"></ae2-sign-up-top>
        <ae2-forgot-password-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT"
          (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgot)="header.closeLoginOrSignup()"
          (onNextStepForgot)="header.nextStepForgot()"></ae2-forgot-password-top>
        <ae2-forgot-password-done-top class="pre-header-element" *ngIf="header.activePreHeader === header.AE2_HEADER_PREHEADER.FORGOT_DONE"
          (onOpenSignup)="header.openSignup()" (onOpenLogin)="header.openLogin()" (onCloseForgotDone)="header.closeLoginOrSignup()"
          (onOpenLoginDone)="header.openLogin()"></ae2-forgot-password-done-top>
      </div>
    </ng-template>

    <!--PRIMEIRA LINHA HEADER-->
    <div class="ae2-header-widget-top" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
      <ae2-header-social-widget [template]="header.headerSocialTemplate" [settings]="settings.site"></ae2-header-social-widget>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center" fxLayoutAlign.xs="center center">
        <ae2-header-login-widget [template]="header.headerLoginTemplate" [isLoginInHeader]="config.isLoginInHeader" (onOpenLogin)="header.openLogin()"
          (onOpenSignup)="header.openSignup()"></ae2-header-login-widget>
        <!-- <ae2-header-unit-selector-widget [template]="header.headerUnitSelectorTemplate"></ae2-header-unit-selector-widget> -->
      </div>
    </div>

    <!--SEGUNDA LINHA HEADER-->
    <div class="ae2-header-widget-bottom" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutAlign.xs="center stretch">


      <ae2-header-logo-widget fxFlex="23%" [template]="header.headerLogoTemplate" [isTransparent]="config.isTransparent">
        <ng-template #customTemplate let-isTransparent>
          <div class="logo-interno">
            <img routerLink="/" class="logo-black" src="/assets/images/mixd/logo.png" alt="Logo Beatcycle." />
            <img routerLink="/" alt="Logo Beatcycle." class="mobile" src="/assets/images/mixd/logo-branco.png">
          </div>

          <a routerLink="/" class="logo logo-transparent">
            <svg id="svg-logo" data-name="logo" xmlns="http://www.w3.org/2000/svg" class="logo-svg desktop">
              <defs>
                <style>
                  .cls-1 {
                    fill: #FFFFFF;
                  }

                  .line-animation {
                    fill: transparent;
                    stroke: #FFFFFF;
                    stroke-width: 6;
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                    animation: dash 4s ease alternate infinite;
                  }

                  @keyframes dash {
                    0% {
                      stroke-dashoffset: 1000;
                    }
                    99% {
                      stroke-dashoffset: 0;
                    }
                    100% {
                      stroke-dashoffset: 0;
                    }
                  }
                </style>
              </defs>
              <g>
                <path id="_Caminho_composto_" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M180.76,387.89V370.76a2.35,2.35,0,0,1,4.7,0v8.11a10.22,10.22,0,0,1,5.78-1.81,10.79,10.79,0,0,1,0,21.58,10.53,10.53,0,0,1-5.87-1.86,2.32,2.32,0,0,1-2.29,1.86,2.35,2.35,0,0,1-2.33-2.33Zm4.7,0a6.08,6.08,0,0,0,1.73,4.27,5.52,5.52,0,0,0,4.06,1.77,5.59,5.59,0,0,0,4.06-1.77,6.26,6.26,0,0,0,0-8.63,5.59,5.59,0,0,0-4.06-1.77,5.52,5.52,0,0,0-4.06,1.77A6.13,6.13,0,0,0,185.46,387.89Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_2" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M205.14,387.85a10.69,10.69,0,0,1,10.53-10.79,10.32,10.32,0,0,1,10.4,10v.22a1.19,1.19,0,0,1,0,.39,2.08,2.08,0,0,1-2.24,1.73H210.06a5.66,5.66,0,0,0,1.47,2.85,6.67,6.67,0,0,0,4.14,1.94,7.05,7.05,0,0,0,4.49-1.08,2.44,2.44,0,0,1,3.24-.13,2,2,0,0,1,0,2.81,10.91,10.91,0,0,1-7.73,2.85A10.71,10.71,0,0,1,205.14,387.85Zm4.7-2.12h12.21c-.43-1.73-2.63-4.27-6.39-4.58A6,6,0,0,0,209.85,385.73Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_3" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M248.61,387.89v8.46a2.3,2.3,0,0,1-2.29,2.29,2.35,2.35,0,0,1-2.24-1.86,9.8,9.8,0,0,1-5.74,1.86,10,10,0,0,1-7.34-3.15,11,11,0,0,1-2.93-7.6,10.89,10.89,0,0,1,2.93-7.6A9.8,9.8,0,0,1,244.07,379a2.34,2.34,0,0,1,2.24-1.9,2.31,2.31,0,0,1,2.29,2.33Zm-4.58,0a6.35,6.35,0,0,0-1.68-4.45,5.33,5.33,0,0,0-4-1.77,5.2,5.2,0,0,0-4,1.77,6.32,6.32,0,0,0-1.64,4.45,6.47,6.47,0,0,0,1.64,4.45,5.44,5.44,0,0,0,4,1.68,5.58,5.58,0,0,0,4-1.68A6.5,6.5,0,0,0,244,387.89Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_4" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M264.19,379.43a2.43,2.43,0,0,1-2.42,2.37h-1.08v12a2.38,2.38,0,0,1,2.37,2.42,2.34,2.34,0,0,1-2.37,2.37,4.81,4.81,0,0,1-4.79-4.79v-12h-1.25a2.37,2.37,0,0,1-2.37-2.37,2.34,2.34,0,0,1,2.37-2.37h1.25V370.8a2.38,2.38,0,0,1,2.42-2.37,2.34,2.34,0,0,1,2.37,2.37v6.26h1.08A2.4,2.4,0,0,1,264.19,379.43Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_5" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M267,387.89a11,11,0,0,1,11-10.83,11.38,11.38,0,0,1,6.86,2.37,2.37,2.37,0,0,1,.47,3.19,2.3,2.3,0,0,1-3.24.43,6.89,6.89,0,0,0-4.1-1.38,6.2,6.2,0,1,0,0,12.39,7,7,0,0,0,4.1-1.38,2.35,2.35,0,0,1,3.24.47,2.39,2.39,0,0,1-.47,3.19,11.89,11.89,0,0,1-6.86,2.29A10.93,10.93,0,0,1,267,387.89Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_6" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M306.61,379.35v16.32a10.2,10.2,0,0,1-14.76,9.24,2.26,2.26,0,0,1-1.12-3.06,2.37,2.37,0,0,1,3.06-.95,5.77,5.77,0,0,0,2.5.6,5.71,5.71,0,0,0,5.4-3.93,8.39,8.39,0,0,1-4.1,1,9.08,9.08,0,0,1-9-9.06V379.35a2.29,2.29,0,0,1,2.24-2.29,2.22,2.22,0,0,1,2.29,2.29v10.19a4.47,4.47,0,0,0,8.93,0V379.35a2.21,2.21,0,0,1,2.24-2.29A2.31,2.31,0,0,1,306.61,379.35Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_7" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M310.32,387.89a11,11,0,0,1,11-10.83,11.38,11.38,0,0,1,6.86,2.37,2.37,2.37,0,0,1,.47,3.19,2.3,2.3,0,0,1-3.24.43,6.89,6.89,0,0,0-4.1-1.38,6.2,6.2,0,1,0,0,12.39,7,7,0,0,0,4.1-1.38,2.35,2.35,0,0,1,3.24.47,2.39,2.39,0,0,1-.47,3.19,11.89,11.89,0,0,1-6.86,2.29A10.93,10.93,0,0,1,310.32,387.89Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_8" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M336.57,368.43A2.4,2.4,0,0,1,339,370.8v25.47a2.4,2.4,0,0,1-4.79,0V370.8A2.39,2.39,0,0,1,336.57,368.43Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_composto_9" data-name="&lt;Caminho composto&gt;" class="cls-1" d="M342.48,387.85A10.69,10.69,0,0,1,353,377.06a10.32,10.32,0,0,1,10.4,10v.22a1.19,1.19,0,0,1,0,.39,2.08,2.08,0,0,1-2.24,1.73H347.4a5.66,5.66,0,0,0,1.47,2.85,6.67,6.67,0,0,0,4.14,1.94,7.05,7.05,0,0,0,4.49-1.08,2.44,2.44,0,0,1,3.24-.13,2,2,0,0,1,0,2.81,10.91,10.91,0,0,1-7.73,2.85A10.71,10.71,0,0,1,342.48,387.85Zm4.7-2.12H359.4c-.43-1.73-2.63-4.27-6.39-4.58A6,6,0,0,0,347.18,385.73Z"
                  transform="translate(-180.76 -251.85)" />
                <path id="_Caminho_" data-name="&lt;Caminho&gt;" class="cls-1 line-animation" d="M353.78,327h0a16.46,16.46,0,0,1-28.11,11.65s-4.83-7.25-4.83-11.65V287.89a9.62,9.62,0,1,0-19.25,0V346.6a16.47,16.47,0,0,1-32.94,0V268.32a9.63,9.63,0,0,0-19.25,0V327a16.47,16.47,0,1,1-32.94,0h0V287.89a9.63,9.63,0,1,0-19.26,0V327a16.48,16.48,0,0,1-16.46,16.46"
                  transform="translate(-180.76 -251.85)" />
              </g>
            </svg>
          </a>
        </ng-template>
      </ae2-header-logo-widget>



      <div fxFlex="5%" (click)="sidenavComponent.openSidenav()" class="menu-lateral">
        <img src="/assets/images/mixd/icon-menu.png" alt="Icone Menu." class="menu-icon-interno">
        <img src="/assets/images/mixd/icon-menu-branco.png" alt="Icone Menu." class="desktop-menu">
      </div>



      <!-- SUBMENU -->
      <ae2-header-menu-widget fxFlex="70%" fxHide.xs="true" [template]="header.headerMenuTemplate" [isSubmenuOpened]="header.submenuVisibility"
        [items]="config.menuItems" (onSubitemsShow)="header.populateSubitems($event)">

        <ng-template #customTemplate let-menu>
          <div class="ae2-header-menu" fxLayout="row" fxLayoutAlign="end stretch">

            <ng-container *ngFor="let item of menu.items.items; let i = index">
              <ng-container *ngIf="item.type !== 'SUBITEMS'">

                <div [ngClass]="{'different-button': i === 1 }" [routerLink]="item.url" class="ae2-header-menu-item" fxLayout="row" fxLayoutAlign="center stretch">

                  <div class="ae2-header-item-page" *ngIf="item.type === 'PAGE'" fxLayout="row" fxLayoutAlign="center stretch">
                    <a fxLayout="row" fxLayoutAlign="center center">
                      <span>{{ item.label }}</span>
                    </a>
                  </div>

                  <div class="ae2-header-item-session" *ngIf="item.type === 'SESSION'" fxLayout="row" fxLayoutAlign="center stretch">
                    <a (click)="menu.navigator(item.url)" fxLayout="row" fxLayoutAlign="center center">
                      <span>{{ item.label }}</span>
                    </a>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </ng-template>
      </ae2-header-menu-widget>
    </div>


    <div class="ae2-header-widget-subitems" fxLayout="row" fxLayoutAlign="end stretch">
      <ae2-header-submenu-widget fxHide.xs="true" [template]="header.headerSubmenuTemplate" [items]="header.getSubmenuItems()"
        [class.active]="header.submenuVisibility" [style.top]="preHeaderHeight + header.headerHeight + 'px'" [backgroundColor]="header.getBackgroundColor(config)"
        (onClose)="header.closeSubmenu()"></ae2-header-submenu-widget>
    </div>
    <ae2-header-sidenav-widget #sidenavComponent [template]="header.headerSidenavTemplate" [items]="config.menuItems" (onOpenLogin)="header.openLogin()"
      (onOpenSignup)="header.openSignup()">
      <ng-template #customTemplate let-sidenav>
        <ng-container *ngIf="sidenav.isOpened">
          <div (click)="sidenav.closeSidenav()" class="menu-lateral-close">
            <img src="/assets/images/mixd/icon-menu-close.png" alt="icone menu close" class="menu-icon-interno">
          </div>
        </ng-container>
        <div class="ae2-header-sidenav-closer" [class.active]="sidenav.isOpened" (click)="sidenav.closeSidenav()">&nbsp;</div>
        <div class="ae2-header-sidenav" [class.active]="sidenav.isOpened">

          <ng-container *ngIf="sidenav.isLoggedIn; else notLoggedIn">
            <div class="heightFull" fxLayout="column" fxLayoutAlign="center center">
              <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav()" routerLink="/minha-conta/meus-dados" fxLayout="row"
                fxLayoutGap="10px" fxLayoutAlign="center center">
                <mat-icon>account_circle</mat-icon>
                <ng-container *ngIf="(sidenav.authStore$ | async)?.performanceSummary?.totalDistance">
                <span class="menuItemCustomColor"> {{(sidenav.authStore$ | async)?.performanceSummary?.totalDistance}} KMs Pedalados | Olá, {{(sidenav.authStore$ | async)?.firstName || '' }}! ({{(sidenav.authStore$ | async)?.totalCredits ||
                  0}} créditos)</span>
                </ng-container>
                <ng-container *ngIf="!(sidenav.authStore$ | async)?.performanceSummary?.totalDistance">
                <span class="menuItemCustomColor"> Olá, {{(sidenav.authStore$ | async)?.firstName || '' }}! ({{(sidenav.authStore$ | async)?.totalCredits ||
                  0}} créditos)</span>
                </ng-container>
              </div>

              <ng-template [ngTemplateOutlet]="menuItems"></ng-template>

              <mat-divider></mat-divider>

              <div class="ae2-header-sidenav-item" (click)="changeMenuMyAccount()" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <span class="menuItemCustomColor pointer">Minha conta</span>
                <ng-container *ngIf="showMyAcountMenu">
                  <mat-icon>arrow_drop_up</mat-icon>
                </ng-container>
                <ng-container *ngIf="!showMyAcountMenu">
                  <mat-icon>arrow_drop_down</mat-icon>
                </ng-container>
              </div>
              <ng-container *ngIf="showMyAcountMenu">
                <ng-container *ngFor="let item of sidenav.myAccountMenu">
                  <div (click)="sidenav.closeSidenav()" [routerLink]="['/', 'minha-conta', item.link]" class="ae2-header-sidenav-item-myaccount menuItemCustomColor"
                    routerLinkActive="active-link" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                    {{item.name}}
                  </div>
                </ng-container>
              </ng-container>

              <div class="ae2-header-sidenav-item menuItemCustomColor" (click)="sidenav.logOut(); sidenav.closeSidenav()" fxLayout="row" fxLayoutGap="10px"
                fxLayoutAlign="start center">
                <mat-icon>exit_to_app</mat-icon> Sair
              </div>
            </div>
          </ng-container>

          <ng-template #notLoggedIn>
            <div class="heightFull"  fxLayout="column" fxLayoutAlign="center center">
              <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openLogin()" fxLayout="row" fxLayoutGap="10px"
                fxLayoutAlign="center center">
                <mat-icon>account_circle</mat-icon>
                <span class="menuItemCustomColor">Entrar</span>
              </div>
              <div class="ae2-header-sidenav-item" (click)="sidenav.closeSidenav(); sidenav.openSignup()" fxLayout="row" fxLayoutGap="10px"
                fxLayoutAlign="center center">
                <mat-icon>mode_edit</mat-icon>
                <span class="menuItemCustomColor">Cadastre-se</span>
              </div>

              <ng-template [ngTemplateOutlet]="menuItems"></ng-template>
            </div>
          </ng-template>

          <ng-template #menuItems>

            <mat-divider></mat-divider>

            <ng-container *ngFor="let item of sidenav.items.items">
              <div *ngIf="['PAGE','SESSION'].indexOf(item.type) >= 0" class="ae2-header-sidenav-item" fxLayout="row" fxLayoutAlign="center center">
                <div class="ae2-header-sidenav-item-page" *ngIf="item.type === 'PAGE'">
                  <a [routerLink]="item.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
                    <span class="menuItemCustomColor">{{ item.label }}</span>
                  </a>
                </div>
                <div class="ae2-header-sidenav-item-session" *ngIf="item.type === 'SESSION'">
                  <a (click)="sidenav.navigator(item.url); sidenav.closeSidenav()" class="sidenav-link">
                    <span class="menuItemCustomColor">{{ item.label }}</span>
                  </a>
                </div>
              </div>
            </ng-container>

            <mat-divider></mat-divider>

            <ng-container *ngIf="sidenav.subitems && sidenav.subitems.length > 0">
              <div *ngFor="let subitem of sidenav.subitems" class="ae2-header-sidenav-item" fxLayout="row" fxLayoutAlign="center center">
                <div class="ae2-header-sidenav-item-page" *ngIf="subitem.type === 'PAGE'">
                  <a [routerLink]="subitem.url" (click)="sidenav.closeSidenav()" class="sidenav-link">
                    <span class="menuItemCustomColor">{{ subitem.label }}</span>
                  </a>
                </div>
                <div class="ae2-header-sidenav-item-session" *ngIf="subitem.type === 'SESSION'">
                  <a (click)="sidenav.navigator(subitem.url); sidenav.closeSidenav()" class="sidenav-link">
                    <span class="menuItemCustomColor">{{ subitem.label }}</span>
                  </a>
                </div>
              </div>
            </ng-container>
          </ng-template>


        </div>
      </ng-template>
    </ae2-header-sidenav-widget>
  </ng-template>

</ae2-header-widget>
