var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MenuInterface, Ae2MyAccountGlobalConfig } from '@angularecommerce/core/components/my-account';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt, 'pt-BR');
var MyAccountCustomConfig = /** @class */ (function (_super) {
    __extends(MyAccountCustomConfig, _super);
    function MyAccountCustomConfig() {
        var _this = _super.call(this) || this;
        // Menus
        _this.menu = [];
        _this.menu.push({ name: 'Meus dados', link: 'meus-dados', icon: 'account_circle' });
        _this.menu.push({ name: 'Alterar minha senha', link: 'alterar-senha', icon: 'lock' });
        _this.menu.push({ name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' });
        _this.menu.push({ name: 'Meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' });
        _this.menu.push({ name: 'Minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' });
        _this.menu.push({ name: 'Meus eventos', link: 'meus-eventos', icon: 'event' });
        _this.menu.push({ name: 'Horário Fixo', link: 'meus-eventos-automaticos', icon: 'event' });
        _this.menu.push({ name: 'Meus créditos', link: 'meus-creditos', icon: 'account_balance' });
        // this.menu.push({ name: 'Compartilhamento de Créditos', link: 'compartilhar-creditos', icon: 'share' });
        _this.menu.push({ name: 'Dados de performance', link: 'dados-de-performance', icon: 'timeline' });
        return _this;
    }
    return MyAccountCustomConfig;
}(Ae2MyAccountGlobalConfig));
export { MyAccountCustomConfig };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
