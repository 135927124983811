import { Component, OnInit, ViewChild } from '@angular/core';
import { Ae2CarouselConfig } from '@angularecommerce/core/components/carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})

export class HomeComponent implements OnInit {

  @ViewChild('video') video: any;
  screem: number;
  iframe: string;
  carouselConfig: Ae2CarouselConfig;
  isBanner: any;
  constructor() {
    window.scrollTo(0, 0);
    this.screem = window.innerWidth;
    // tslint:disable-next-line
    this.iframe = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/VseXieHbHk8?rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>`;
  }

  videoPlay(): void {
    const modal: HTMLElement = <HTMLElement>document.querySelector('#modal-video-home');
    const section: HTMLElement = <HTMLElement>document.querySelector('section.full-banner');
    modal.style.width = document.querySelector('section.full-banner').clientWidth + 'px';
    modal.style.height = document.querySelector('section.full-banner').clientHeight + 'px';
    if (modal.getAttribute('open') === 'true') {
      modal.setAttribute('open', 'false');
      setTimeout(() => {
        if (this.screem > 1024) {
          this.video.nativeElement.play(); // Play no vídeo background
        }
        section.style.zIndex = '1';
        modal.querySelector('.modal-content').innerHTML = this.iframe;
      }, 1000);
    } else {
      if (this.screem > 1024) {
        this.video.nativeElement.pause(); // Play no vídeo background
      }
      section.style.zIndex = '10';
      modal.querySelector('.modal-content').innerHTML = this.iframe;
      modal.querySelector('iframe').setAttribute('src', modal.querySelector('iframe').getAttribute('src') + '&autoplay=1');
      modal.setAttribute('open', 'true');
    }
  }

  ngOnInit(): void {
    this.carouselConfig = new Ae2CarouselConfig();
    this.carouselConfig.useDottedOverLay = false;
    this.clientHeight();
  }
  clientHeight(): void {
    window.addEventListener('scroll', () => {
      if (document.querySelector('section.full-banner')) {
        if ((window.scrollY > document.querySelector('section.full-banner').clientHeight)
          && document.querySelector('#modal-video-home').getAttribute('open') === 'true') {
          this.videoPlay();
        }
      }
    });
  }
  creatBanner(id: any): void {
    setTimeout(() => {
      if (!this.isBanner) {
        this.isBanner = id;
      }
    }, 100);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  // ngOnDestroy(): void {
  //   window.removeEventListener('scroll');
  // }

}
