<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
	<ng-template ae2CarouselOverlayContent
	let-id="id"
	let-title="title"
	let-description="description"
	let-ctaUrl="ctaUrl"
	let-ctaLabel="ctaLabel">
		{{creatBanner(id)}}
		<div class="ae2Carousel__overlay-content-default">
			<h1 class="ae2Carousel__overlay-content-default__title">{{ title }}</h1>
			<div class="ae2Carousel__overlay-content-default__subtitle" [innerHTML]="description"></div>
			<a [routerLink]="ctaUrl">{{ ctaLabel }}</a>
		</div>

	</ng-template>
</ae2-carousel-container>
<section *ngIf="!isBanner" class="full-banner" style="background-image: url('/assets/images/mixd/full-banner-home.jpg');">
	<video width="100%" loop *ngIf="screem > 1024" #video muted autoplay="autoplay">
		<source src="/assets/videos/intro.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
	</video>
	<div id="modal-video-home" class="modal-video" open="false">
		<button type="button" class="button-close" (click)="videoPlay()">
			<img src="/assets/images/mixd/icon-menu-close.png" alt="Fechar.">
		</button>
		<div class="filter" (click)="videoPlay();"></div>
		<div class="vertical-align">
			<div class="modal-content">
				<!-- Via TS -->
			</div>
		</div>
	</div>
	<a (click)="videoPlay();" title="Clique para iniciar o vídeo" class="button-scroll-chamada">
		<img src="/assets/images/mixd/icon-video.png" alt="Icone Play." class="icon-video">
		<img src="/assets/images/mixd/icon-video-balls.png" alt="Icone Play." class="icon-video-balls">
	</a>
</section>
<section class="chamada" id="scroll-chamada">
	<div class="chamada-content">
		<div class="container">
			<div class='bicicleta green'>
				<div class="pedal"></div>
			</div>
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
				<div fxFlex="50" fxFlex.xs="auto">
					<div class="box-title">
						<p class="title">
							<span>Aperte o play</span>
							<br>da vida</p>
					</div>
				</div>
				<div fxFlex="50" fxFlex.xs="auto">
					<div class="descricao">
						<p>
							A música invade a alma. O coração acelera no ritmo da batida. De pedalada a pedalada, você se sente mais decidido em vencer
							os fantasmas que tanto te afligem. Stress, obesidade, diabetes, depressão, se transformam em gotas de suor e caem
							no chão, cansadas, mortas praticamente. O que fica é uma sensação de bem-estar contagiante e a vontade de começar
							tudo novamente.
						</p>
						<a href="#scroll-planos" class="button-roll-plano" scrollTo>Escolha seu plano</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="institucional" id="scroll-institucional">
	<div class="institucional-content">
		<div class="container">
			<div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
				<div class="left" fxFlex="53" fxFlex.xs="auto">
					<img src="/assets/images/mixd/selo-laranjado-beatcycle.svg" alt="Selo Laranjado Beatcycle." class="selo">
					<div class='bicicleta'>
						<div class="pedal"></div>
					</div>
				</div>
				<div class="right" fxFlex="47" fxFlex.xs="auto">
					<h1 class="title after verde large">
						<span>O que é o</span>
						<br>Beatcycle</h1>
					<p>
						Imagine um studio de bike indoor onde você e um grupo de pessoas pedalam enquanto uma música empolgante toca. E em meio ao
						jogo de luzes, você acompanha o seu desempenho e o de seus “adversários”, tudo em tempo real, através de uma tecnologia
						de última geração que simula uma pedalada de verdade. Assim é o Beatcycle. O studio de bike indoor que chegou ao Brasil
						para oferecer uma experiência completamente nova e inspiradora àqueles que buscam saúde e bem-estar através da atividade
						física.
					</p>
					<a href="#" routerLink="/estudio" class="button-quero-conhecer">quero conhecer</a>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="planos" id="scroll-planos">
	<app-plans></app-plans>
</section>

<section class="team">
	<div class="team-title">
		<div class="container">
			<div fxLayout="row">
				<div fxFlex="100">
					<h2 class="title medium verde after center">Um time em busca da batida perfeita</h2>
				</div>
			</div>
			<div fxLayout="row">
				<div fxFlex="100">
					<p>Somente uma equipe formada por grandes profissionais pode despertar em você a vontade de se superar a cada dia. E esta
						equipe está aqui.</p>
				</div>
			</div>
		</div>
	</div>
	<a href="#" routerLink="/instrutores" class="team-content">
		<div class="team-content">
			<div class="vertical-align">
				<p>Beatcycle Team</p>
			</div>
		</div>
	</a>
</section>
