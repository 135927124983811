var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Ae2HeaderWidgetConfig, Ae2HeaderMenu, Ae2HeaderSubmenu, Ae2HeaderLinkToSession, Ae2HeaderLinkToPage } from '@angularecommerce/core/components/header-widget';
import { Ae2SignUpStepperConfig } from '@angularecommerce/core/components/sign-up-steps';
import { Ae2SignUpStepSignup, Ae2SignUpStepAddress, Ae2SignUpStepFitness, Ae2SignUpStepProfile } from '@angularecommerce/core/components/sign-up-steps';
var Ae2CustomSignUpStepSignup = /** @class */ (function (_super) {
    __extends(Ae2CustomSignUpStepSignup, _super);
    function Ae2CustomSignUpStepSignup() {
        var _this = _super.call(this) || this;
        // remove o campo Enviar Senha
        _this.fields = _this.fields.filter(function (item) { return item.name !== 'sendPasswordToUser'; });
        return _this;
    }
    return Ae2CustomSignUpStepSignup;
}(Ae2SignUpStepSignup));
export var CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new Ae2CustomSignUpStepSignup(),
    new Ae2SignUpStepProfile(),
    new Ae2SignUpStepAddress(),
    new Ae2SignUpStepFitness()
]);
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent() {
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.showMyAcountMenu = false;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.createSignupConfig();
        this.headerConfig = new Ae2HeaderWidgetConfig();
        this.headerConfig.menuItems = new Ae2HeaderMenu();
        this.headerConfig.menuItems.items = [
            new Ae2HeaderSubmenu('Estudio', [
                new Ae2HeaderLinkToSession('Quem Somos', 'scroll-institucional'),
                new Ae2HeaderLinkToPage('Estúdio', '/estudio'),
                new Ae2HeaderLinkToPage('Instrutores', '/instrutores'),
                new Ae2HeaderLinkToPage('Perguntas Frequentes', '/faq'),
                new Ae2HeaderLinkToSession('Contato', 'scroll-footer'),
            ]),
            new Ae2HeaderLinkToPage('Planos', '/pacotes'),
            new Ae2HeaderLinkToPage('Horários de Aula', '/agenda')
        ];
        this.headerConfig.backgroundColor = '#f0f0f0';
    };
    HeaderComponent.prototype.createSignupConfig = function () {
        this.signupConfig = CustomAe2SignupConfig;
    };
    HeaderComponent.prototype.printHeader = function (header) {
        console.log(header);
    };
    HeaderComponent.prototype.changeMenuMyAccount = function () {
        this.showMyAcountMenu = !this.showMyAcountMenu;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
