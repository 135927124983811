import { Component, OnInit } from '@angular/core';
import { Fields } from '@angularecommerce/core/components/sign-up';
import {
  Ae2HeaderWidgetConfig,
  Ae2HeaderMenu,
  Ae2HeaderSubmenu,
  Ae2HeaderLinkToSession,
  Ae2HeaderLinkToPage
} from '@angularecommerce/core/components/header-widget';
import {
  Ae2SignUpStepperConfig
} from '@angularecommerce/core/components/sign-up-steps';
import {
  Ae2SignUpStepSignup,
  Ae2SignUpStepAddress,
  Ae2SignUpStepFitness,
  Ae2SignUpStepProfile
} from '@angularecommerce/core/components/sign-up-steps';

class Ae2CustomSignUpStepSignup extends Ae2SignUpStepSignup {
  constructor() {
    super();

    // remove o campo Enviar Senha
    this.fields = this.fields.filter(item => item.name !== 'sendPasswordToUser');
  }
}

export const CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
  new Ae2CustomSignUpStepSignup(),
  new Ae2SignUpStepProfile(),
  new Ae2SignUpStepAddress(),
  new Ae2SignUpStepFitness()
]);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
  signupConfig: Ae2SignUpStepperConfig;
  signupFields: Fields;

  showMyAcountMenu: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    this.createSignupConfig();

    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderSubmenu('Estudio', [
        new Ae2HeaderLinkToSession('Quem Somos', 'scroll-institucional'),
        new Ae2HeaderLinkToPage('Estúdio', '/estudio'),
        new Ae2HeaderLinkToPage('Instrutores', '/instrutores'),
        new Ae2HeaderLinkToPage('Perguntas Frequentes', '/faq'),
        new Ae2HeaderLinkToSession('Contato', 'scroll-footer'),
      ]),
      new Ae2HeaderLinkToPage('Planos', '/pacotes'),
      new Ae2HeaderLinkToPage('Horários de Aula', '/agenda')
    ];

    this.headerConfig.backgroundColor = '#f0f0f0';
  }

  createSignupConfig(): void {
    this.signupConfig = CustomAe2SignupConfig;
  }

  printHeader(header: any): void {
    console.log(header);
  }

  changeMenuMyAccount(): void {
    this.showMyAcountMenu = !this.showMyAcountMenu;
  }
}
